import React from "react";
import { StaticQuery, graphql } from "gatsby";
import ReactHtmlParser from "react-html-parser";
import PropTypes from "prop-types";
import Layout from "../components/layout";
import Mailchimp from "react-mailchimp-form";
import SEO from "../components/seo";

const JoinUs = ({ location }) => {
  return (
    <StaticQuery
    query={graphql`
    query {
      wpgraphql {
        page(id: "/join-our-mission/", idType: URI) {
          content
          title
          slug
          uri
        }
      }
    }
    `}
    render={data =>   
  <Layout location={location}>
    <SEO
      keywords={[`virtualmoon`, `vr`, `moon`, `space`]}
      title="Join Us"
    />
    <section className="text-left pt-24 sm:ml-5 md:pl-20 md:ml-32">
    <h1 className="text-white font-title font-thin text-4xl mb-5">{data.wpgraphql.page.title}</h1>
      <div className="md:w-2/3 sm:w-full">
        <div className="text-white font-body md:w-5/6 sm:w-full">{ ReactHtmlParser(data.wpgraphql.page.content) }</div>;
        <h3 className="text-white font-body md:w-5/6 sm:w-full">Sign up for the e-mail list and receive exclusive first access to news and information on the project.</h3> 
      <Mailchimp
          action='https://space.us18.list-manage.com/subscribe/post?u=41454ef8e14ea4223cb001931&amp;id=851600af3f'
          fields={[
            {
              name: 'EMAIL',
              placeholder: 'Email',
              type: 'email',
              required: true
            },
            {
              name: 'FNAME',
              placeholder: 'First Name',
              type: 'text',
              required: true
            },
            {
              name: 'LNAME',
              placeholder: 'Last Name',
              type: 'text',
              required: true
            },
            {
              name: 'FREETEXT',
              placeholder: 'Tell us about yourself and your interest in Virtual Moon',
              type: 'text',
              required: true
            }
          ]}
          className='mc-field-group'
          buttonClassName='formButton'
        />
      </div>
      </section>
      <section>
        <div style={{minHeight: '150px'}}></div>
      </section>
      {/* <section className="text-left pt-24 pl-20 ml-32">
      <div id="sponsorship" className="text-white font-body w-5/6">
        <h1 className="text-white font-title font-thin text-4xl mb-5">Support Our Mission</h1>
        <h3>Become a crew member at various sponsorship levels for exclusive access</h3> 
        <h3>Visitor</h3> 
        <ul>
          <li>You can go to the Moon for free</li>
          <li>You get a free customizable Avatar</li>
          <li>A free Advanced Space Suit (customizable and upgradable)</li>
          <li>A free basic pressurized rover (customizable and upgradable – no sensors)</li>
          <li>A free basic transporter drone (customizable and upgradable)</li>
          <li>Free access to Malapert</li>
          <li>Access to free events</li>
          <li>50 hours of access to the Lunar Academy (you can buy more at L:100/hour)</li>
          <li>Accumulate L:10 per hour online</li>
        </ul>

        <h3>Explorer</h3> 
        <ul>
          <li>Subscribe at $2 / Month minimum</li>
          <li>You get L:100 / per dollar in exchange</li>
          <li>Accumulate L:25 per hour online</li>
          <li>Access to advanced sensors (limited range, sensitivity - false readings)</li>
          <li>Free Beacon Locator (to hunt Easter Eggs – limited range, sensitivity – false readings)</li>
          <li>Unlimited access to the Lunar Academy</li>
          <li>Discounted Access to all events (25%)</li>
          <li>Floating land claims</li>
          <li>Your own downloadable Virtual Moon</li>
        </ul>

        <h3>Prospector</h3> 
        <ul>
          <li>Subscribe at $5 / Month minimum</li>
          <li>You get L:100 / per dollar in exchange</li>
          <li>Accumulate L:50 per hour online</li>
          <li>Access to advanced sensors (increased range, increased sensitivity, no false readings)</li>
          <li>Free Easter Egg Beacon Locator (increased range, increased sensitivity, no false readings)</li>
          <li>Unlimited access to the Lunar Academy</li>
          <li>Discounted access to all events (50%)</li>
          <li>Floating land claims</li>
          <li>Sell resources to commodities market</li>
          <li>Your own downloadable Virtual Moon</li>
        </ul>

        <h3>Developer</h3> 
        <ul>
          <li>Subscribe at $10 / Month minimum</li>
          <li>You get L:100 / per dollar in exchange</li>
          <li>Accumulate L:100 per hour online</li>
          <li>Access to advanced sensors (increased range, increased sensitivity, no false readings)</li>
          <li>Free Easter Egg Beacon Locator (increased range, increased sensitivity, no false readings)</li>
          <li>Unlimited access to the Lunar Academy</li>
          <li>Free Access to all events</li>
          <li>Floating land claims</li>
          <li>Free building licenses</li>
          <li>Your own downloadable Virtual Moon</li>
        </ul>
      <p></p>
      </div>
      </section> */}
  </Layout>
   }
   />
)
}

JoinUs.propTypes = {
  location: PropTypes.object
};

export default JoinUs;